import * as React from "react";
import useSWR from "swr";
import { fetcher } from "../hooks";
import { identity } from "../util";

const baseEndpoint = "/api/influencer/sections";
const fiveMinutes = 1000 * 60 * 5; // 5 minutes

const isValidSection = (section) => {
  return [
    "liveAndUpcoming",
    "popularFranchises",
    "popularCharacters",
    "popularProducts",
    "presigned",
    "signAnytime",
    "special",
    "featured",
    "evenMore",
  ].includes(section);
};

export default function useHomepageSection(
  inView,
  section,
  enricher = identity,
) {
  const didFetch = React.useRef(false);

  if (!isValidSection(section)) {
    throw new Error(`Invalid section: ${section}`);
  }

  const endpoint = React.useMemo(() => {
    if (didFetch.current || inView) {
      didFetch.current = true;
      return `${baseEndpoint}/${section}`;
    }

    return null;
  }, [inView, section]);

  const { data, error, mutate } = useSWR(endpoint, fetcher, {
    focusThrottleInterval: fiveMinutes,
  });

  const results = React.useMemo(() => {
    if (!data) {
      return [];
    }

    const { results } = data;

    if (!Array.isArray(results)) {
      return [];
    }

    return enricher(results);
  }, [data, enricher]);

  return {
    results,
    isLoading: !data && !error,
    isError: Boolean(error),
    error,
    refresh: mutate,
  };
}
