import { getParameter } from "./getParameter";
import downloadFile, { downloadCSVFromText } from "./downloadFile";
import {
  annoyingFireworks,
  realisticConfetti,
  simpleConfetti,
} from "./confetti";

import getCookie from "./getCookie";

import deleteCookie from "./deleteCookie";

import * as autographPurchase from "./autographPurchase";
import * as featureFlags from "./featureFlags";

import numberFormatter from "./formatNumber";
import currencyFormatter from "./formatCurrency";

import formatDate, {
  matchesDateTime,
  dateFormatter,
  dateTimeFormatter,
  dateTimeFormatterUS,
} from "./formatDate";

import { formatDateRangeTZ, formatDateRangeReverseTZ } from "./formatDateRange";

import apiCall from "./apiCall";

import {
  formatDateYearStr,
  formatDateYearUTCStr,
  formatDateYearReverseStr,
  formatDateTimeStr,
  formatDateToDateTimeUTCStr,
  formatDateReverseTZ,
  formatDateMomentYear,
  formatDateStrTimeReverseTZ,
  formatDateToDateTimeReverseTZ,
  formatDateTZ,
} from "./formatSingleDate";

import formatWith, {
  formatWithBool,
  formatWithDate,
  formatWithTrim,
  formatWithLast4,
  formatWithCurrency,
} from "./formatWith";

import { formatPhoneNumber } from "./formatPhoneNumber";

import generateId from "./generateId";

import inputValidation from "./inputValidation";
import copyToClipboard from "./copyToClipboard";
import invariant, { assertNotEmpty } from "./invariant";

import addWordBreakHints from "./addWordBreakHints";

import localStorageUtils, {
  getWithExpiry,
  setWithExpiry,
} from "./localStorage";
import logger from "./logger";

import {
  isPeterWeller,
  isPeterWellerOrder,
  hasPurchasedQuote,
  hasPurchasedCharacterName,
} from "./peterWeller";

import {
  capitalizeWord,
  joinEnglish,
  joinEnglishReducer,
  joinEnglishPick,
  titleCase,
  limitLength,
  validateEmail,
} from "./text";

import {
  add,
  and,
  binary,
  coalesce,
  compose,
  createSorter,
  curry,
  debounce,
  deepTrim,
  demethodize,
  diffLengths,
  diffObject,
  entries,
  every,
  filter,
  firstTruthy,
  head,
  identity,
  ifEmpty,
  increment,
  isEmpty,
  last,
  map,
  mapObject,
  mapObjectAll,
  noop,
  not,
  omit,
  or,
  pick,
  pipe,
  reduce,
  reduceIfNotIn,
  some,
  sum,
  toBoolean,
  urnary,
  values,
  zipMap,
} from "./fp";

import { isValidValue, looksLikeURL } from "./utils";

const isProduction = process.env.REACT_APP_ENV === "production";
const isDev = process.env.REACT_APP_ENV === "dev";
const isStaging = process.env.REACT_APP_ENV === "staging";

export { isProduction, isDev, isStaging };

export {
  add,
  addWordBreakHints,
  and,
  annoyingFireworks,
  apiCall,
  assertNotEmpty,
  autographPurchase,
  binary,
  capitalizeWord,
  coalesce,
  compose,
  copyToClipboard,
  createSorter,
  currencyFormatter,
  curry,
  dateFormatter,
  dateTimeFormatter,
  dateTimeFormatterUS,
  debounce,
  deepTrim,
  deleteCookie,
  demethodize,
  diffLengths,
  diffObject,
  downloadCSVFromText,
  downloadFile,
  entries,
  every,
  featureFlags,
  filter,
  firstTruthy,
  formatDate,
  formatDateMomentYear,
  formatDateRangeReverseTZ,
  formatDateRangeTZ,
  formatDateReverseTZ,
  formatDateStrTimeReverseTZ,
  formatDateTimeStr,
  formatDateToDateTimeReverseTZ,
  formatDateToDateTimeUTCStr,
  formatDateTZ,
  formatDateYearReverseStr,
  formatDateYearStr,
  formatDateYearUTCStr,
  formatPhoneNumber,
  formatWith,
  formatWithBool,
  formatWithCurrency,
  formatWithDate,
  formatWithLast4,
  formatWithTrim,
  generateId,
  getCookie,
  getParameter,
  getWithExpiry,
  hasPurchasedCharacterName,
  hasPurchasedQuote,
  head,
  identity,
  ifEmpty,
  increment,
  inputValidation,
  invariant,
  isEmpty,
  isPeterWeller,
  isPeterWellerOrder,
  isValidValue,
  joinEnglish,
  joinEnglishPick,
  joinEnglishReducer,
  last,
  limitLength,
  localStorageUtils,
  logger,
  looksLikeURL,
  map,
  mapObject,
  mapObjectAll,
  matchesDateTime,
  noop,
  not,
  numberFormatter,
  omit,
  or,
  pick,
  pipe,
  realisticConfetti,
  reduce,
  reduceIfNotIn,
  setWithExpiry,
  simpleConfetti,
  some,
  sum,
  titleCase,
  toBoolean,
  urnary,
  validateEmail,
  values,
  zipMap,
};
